import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback
} from "react";
import { Link } from "react-router-dom";
import utils from "web3-utils";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import TwentyFortyEight from "../../abis/TwentyFortyEight.json";
import "./Game.scss";
import Board from "../Board";

function Game(props) {
  const [currentGame, setCurrentGame] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [account, setAccount] = useState("");
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [isOwner, setIsOwner] = useState(false);
  const [locked, setLocked] = useState(true);
  const [poolBalance, setPoolBalance] = useState(0);

  const { register, handleSubmit, errors } = useForm();

  const boardRef = useRef();

  let signingService;

  if (window.connex) {
    signingService = window.connex.vendor.sign("tx");
  }

  const {
    highscore,
    colCount,
    rowCount,
    boxSize,
    boxMargin,
    updateScores,
    address
  } = props;

  const getPoolBalance = async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getPoolBalance"
    );
    const method = window.connex.thor.account(address).method(abi);

    try {
      const { decoded } = await method.call();

      setPoolBalance(decoded[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentGame = useCallback(async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getCurrentGame"
    );
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call(account);

    console.log(decoded);
    setGameStarted(decoded.started);
    setCurrentGame(decoded.gameCount);
  }, [account]);

  const unlock = async () => {
    const certSign = window.connex.vendor.sign("cert");
    const abi = TwentyFortyEight.abi.find(item => item.name === "owner");
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call();

    certSign
      .request({
        purpose: "identification",
        payload: {
          type: "text",
          content: "Allow access to public address and game status"
        }
      })
      .then(({ annex }) => {
        setAccount(annex.signer);
        setLocked(false);

        if (annex.signer === decoded[0]) {
          setIsOwner(true);
        }
      });
  };

  const getGamesPlayed = useCallback(async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getGamesPlayed"
    );

    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call(account);

    console.log(decoded);
    setGamesPlayed(decoded[0]);
  }, [account]);

  const onSetGameStarted = value => {
    setGameStarted(value);
  };

  const init = async () => {
    const abi = TwentyFortyEight.abi.find(item => item.name === "init");
    const method = window.connex.thor.account(address).method(abi);
    const feeFraction = utils.toWei("0.1", "ether");
    const price = utils.toWei("40", "ether");

    signingService
      .signer(account)
      .comment("init function by adding feeFraction");

    const clause = method.asClause(feeFraction, price);

    signingService.request([clause]).then(({ txid }) => {
      getReceipt(txid);
    });
  };

  const getFeeFraction = async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getFeeFraction"
    );
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call();

    console.log(decoded);
  };

  const getCurrentPrice = async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getCurrentPrice"
    );
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call();

    console.log(decoded);
  };

  const getTotalGames = async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getTotalGames"
    );
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call();

    console.log(decoded);
  };

  const getOwnerBalance = async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getOwnerBalance"
    );
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call();

    console.log(decoded);
  };

  const withdraw = () => {
    const abi = TwentyFortyEight.abi.find(item => item.name === "withdraw");
    const method = window.connex.thor.account(address).method(abi);

    const clause = method.asClause();

    signingService.request([clause]).then(({ txid }) => {
      getReceipt(txid);
    });
  };

  const onSubmit = ({ secret }) => {
    if (!account) return;

    const abi = TwentyFortyEight.abi.find(item => item.name === "startGame");
    const method = window.connex.thor.account(address).method(abi);
    const secretHash = utils.soliditySha3(secret);
    const price = utils.toWei("40", "ether");
    signingService.signer(account).comment("start game");

    method.value(price);

    const clause = method.asClause(account, secretHash);

    signingService.request([clause]).then(({ txid }) => {
      getReceipt(txid).then(() => {
        setGameStarted(true);
      });
    });
  };

  const newHighscore = (value, score) => {
    return new Promise((resolve, reject) => {
      const abi = TwentyFortyEight.abi.find(
        item => item.name === "newHighscore"
      );
      const method = window.connex.thor.account(address).method(abi);
      const secretHash = utils.soliditySha3(value);
      signingService.signer(account).comment("new highscore!");

      const clause = method.asClause(score, currentGame, secretHash, account);

      signingService.request([clause]).then(({ txid }) => {
        getReceipt(txid).then(() => {
          endGame();
          resolve();
        });
      });
    });
  };

  const endGame = () => {
    console.log(currentGame);
    return new Promise((resolve, reject) => {
      const abi = TwentyFortyEight.abi.find(item => item.name === "endGame");
      const method = window.connex.thor.account(address).method(abi);
      signingService.signer(account).comment("end game");

      const clause = method.asClause(account);

      signingService.request([clause]).then(({ txid }) => {
        getReceipt(txid).then(() => {
          clearCacheForNewGame();
          getGamesPlayed();
          getCurrentGame();

          resolve();
        });
      });
    });
  };

  const getReceipt = txid => {
    const transaction = window.connex.thor.transaction(txid);

    Swal.fire({
      title: "Waiting for confirmation",
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    return new Promise((resolve, reject) => {
      const poller = async () => {
        const ticker = window.connex.thor.ticker();

        for (let i = 0; i < 10; i++) {
          await ticker.next();
          const receipt = await transaction.getReceipt();

          if (receipt?.reverted !== true) {
            Swal.close();
            resolve();
          }
        }
      };

      poller();
    });
  };

  const clearCacheForNewGame = () => {
    let cache = JSON.parse(
      localStorage.getItem(rowCount + "x" + colCount + "-grid")
    );

    localStorage.setItem(
      rowCount + "x" + colCount + "-grid",
      JSON.stringify({ highscore: cache.highscore })
    );
  };

  useEffect(() => {
    if (window.connex) {
      getPoolBalance();

      setInterval(getPoolBalance, 10000);
    }
  }, []);

  useEffect(() => {
    if (window.connex && account) {
      getGamesPlayed();
      getCurrentGame();
    }
  }, [account, getGamesPlayed, getCurrentGame]);

  return (
    <div>
      {isOwner && (
        <Fragment>
          <div className="button-wrapper">
            <button className="button" onClick={init}>
              Init
            </button>
          </div>

          <div className="button-wrapper">
            <button className="button" onClick={withdraw}>
              Withdraw
            </button>
          </div>

          <div className="button-wrapper">
            <button className="button" onClick={getFeeFraction}>
              Get Fee Fraction
            </button>
          </div>

          <div className="button-wrapper">
            <button className="button" onClick={getTotalGames}>
              Get Total Games
            </button>
          </div>

          <div className="button-wrapper">
            <button className="button" onClick={getCurrentPrice}>
              Get Current Price
            </button>
          </div>

          <div className="button-wrapper">
            <button className="button" onClick={getOwnerBalance}>
              Get Owner Balance
            </button>
          </div>
        </Fragment>
      )}
      {!window.connex ? (
        <div className="notice">Connex not found</div>
      ) : (
        <>
          {locked && (
            <div className="button-wrapper">
              <button className="button" onClick={unlock}>
                Unlock Account
              </button>
            </div>
          )}
        </>
      )}
      {gameStarted ? (
        <Fragment>
          <div className="button-wrapper">
            <button className="button" onClick={endGame}>
              End Game
            </button>
          </div>

          <Board
            colCount={colCount}
            rowCount={rowCount}
            boxSize={boxSize}
            boxMargin={boxMargin}
            ref={boardRef}
            highscore={highscore}
            endGame={endGame}
            clearCacheForNewGame={clearCacheForNewGame}
            setGameStarted={onSetGameStarted}
            newHighscore={newHighscore}
            updateScores={updateScores}
          />
          <div className="board-footer">
            <small>games played: {gamesPlayed}</small>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {!locked && (
            <Fragment>
              <p>
                <strong className="important">Remember your secret!</strong>{" "}
                Entering a secret is an extra layer of security. In the case of
                you getting a new highscore you'll be required to enter your
                previously entered secret to withdraw all the funds from the
                pool.
              </p>
              {errors.secret && (
                <div className="error">
                  <small>A secret is required</small>
                </div>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  name="secret"
                  ref={register({ required: true })}
                  placeholder="Enter secret to start a new game"
                />
                <button className="button" type="submit">
                  Start Game
                </button>
              </form>
            </Fragment>
          )}
        </Fragment>
      )}
      <div className="actions">
        <div className="pool-balance">
          Balance in Pool:{" "}
          <span className="balance">
            {utils.fromWei(poolBalance.toString(), "ether")} VET
          </span>
        </div>
        <Link to="/history">Check the Highscores</Link>
      </div>
      <p>
        <strong className="important">How to play:</strong> Unlock your account
        so 2048x can get your game status and public address. If you haven't
        started a new game <strong>enter your secret</strong> to start a new
        game for 40 VET. <strong>40 VET will be added to the pool</strong> which
        if you get the highscore you will receive the total amount in the pool.
        Use your <strong>arrow keys</strong> to move the tiles. When two tiles
        with the same number touch, they <strong>merge into one!</strong>
      </p>
    </div>
  );
}

export default Game;
