import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TwentyFortyEight from "./abis/TwentyFortyEight.json";
import useWindowSize from "./classes/hooks/useWindowSize";
import QRCode from "qrcode.react";
import Modal from "react-modal";
import Timer from "react-compound-timer";

import Game from "./components/Game";
import History from "./components/History";

const colCount = 4;
const rowCount = 4;

const address = "0xA2e5BB5aB0D14a0618C6f0cC2EeFf046d8B3c0d6";

const getGridCache = () => {
  return JSON.parse(localStorage.getItem("grid"));
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement("#root");

function App() {
  const gridCache = getGridCache();
  const [windowWidth, windowHeight] = useWindowSize();
  const [boxSize, setBoxSize] = useState(gridCache ? gridCache.boxSize : 100);
  const [boxMargin] = useState(10);
  const [score, setScore] = useState(0);
  const [highscore, setHighscore] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showModalButton, setShowModalButton] = useState(true);
  const [showNotice, setNotice] = useState(true);

  const headerHeight = 240;

  const updateScores = ({ score, highscore }) => {
    setScore(score);
    setHighscore(highscore);
  };

  const getHighscore = async () => {
    const abi = TwentyFortyEight.abi.find(item => item.name === "getHighscore");
    const method = window.connex.thor.account(address).method(abi);
    const { decoded } = await method.call();

    setHighscore(decoded[0]);
  };

  const handleSetHighscore = balance => {
    setHighscore(score);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (window.connex) {
      getHighscore();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "grid",
      JSON.stringify({
        colCount: colCount,
        rowCount: rowCount,
        boxSize: boxSize
      })
    );
  }, [boxSize]);

  useEffect(() => {
    let containerWidth = windowWidth - windowWidth * 0.2;
    let containerHeight = windowHeight - (headerHeight + 50);
    let boxSizeForWidth = containerWidth / colCount - boxMargin;
    let boxSizeForHeight = containerHeight / rowCount - boxMargin;
    let boxSizeToBe = Math.floor(Math.min(boxSizeForWidth, boxSizeForHeight));

    if (boxSizeToBe > 0) setBoxSize(100 > boxSizeToBe ? boxSizeToBe : 100);
  }, [windowWidth, windowHeight, boxMargin]);

  return (
    <div
      className="container"
      style={{
        width: Math.floor(colCount * boxSize + boxMargin * colCount + boxMargin)
      }}
    >
      <Router>
        <a className="info" href="https://vtho.play2048x.com">
          Want to use your VTHO? Click here to checkout our VTHO version!
        </a>
        <div className="header">
          <h1 className="title">
            2048
            <span>X</span>
          </h1>
          <div className="scores-container">
            <div className="score score-panel">
              <label>Score</label>
              {score}
            </div>
            <div className="highscore score-panel">
              <label>Best</label>
              {highscore}
            </div>
          </div>
        </div>
        {showNotice && (
          <div className="notice">
            <span>
              Comet is having issues. Please open 2048 in the VeChain Mobile
              wallet or Sync
            </span>
            <Timer
              direction="backward"
              initialTime={11000}
              checkpoints={[
                {
                  time: 0,
                  callback: () => setNotice(false)
                }
              ]}
            >
              <Timer.Seconds />
            </Timer>
          </div>
        )}
        {showModalButton && (
          <button className="open-modal" onClick={openModal}>
            <span>Scan QR Code</span>
            <Timer
              direction="backward"
              initialTime={10000}
              checkpoints={[
                {
                  time: 0,
                  callback: () => setShowModalButton(false)
                }
              ]}
            >
              <Timer.Seconds />
            </Timer>
          </button>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <QRCode size={200} value="https://www.play2048x.com/" />
        </Modal>
        <Switch>
          <Route path="/history">
            <History />
          </Route>
          <Route path="/">
            <Game
              address={address}
              colCount={colCount}
              rowCount={rowCount}
              boxSize={boxSize}
              boxMargin={boxMargin}
              highscore={highscore}
              updateScores={updateScores}
              handleSetHighscore={handleSetHighscore}
            />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
