import React, { useState, useEffect } from "react";
import utils from "web3-utils";
import TwentyFortyEight from "../../abis/TwentyFortyEight.json";

const address = "0xA2e5BB5aB0D14a0618C6f0cC2EeFf046d8B3c0d6";

function History() {
  const [winners, setWinners] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [highscores, setHighscores] = useState([]);

  const getWinners = async () => {
    const abi = TwentyFortyEight.abi.find(item => item.name === "getWinners");

    const method = window.connex.thor.account(address).method(abi);
    const { reverted, decoded } = await method.call();

    if (!reverted) {
      setWinners(decoded[0]);
    }
  };

  const getPayouts = async () => {
    const abi = TwentyFortyEight.abi.find(item => item.name === "getPayouts");

    const method = window.connex.thor.account(address).method(abi);
    const { reverted, decoded } = await method.call();

    if (!reverted) {
      setPayouts(decoded[0]);
    }
  };

  const getHighscores = async () => {
    const abi = TwentyFortyEight.abi.find(
      item => item.name === "getHighscores"
    );

    const method = window.connex.thor.account(address).method(abi);
    const { reverted, decoded } = await method.call();

    if (!reverted) {
      setHighscores(decoded[0]);
    }
  };

  useEffect(() => {
    getPayouts();
    getWinners();
    getHighscores();
  }, []);

  return (
    <div>
      <h2>History</h2>

      {winners.map((winner, index) => (
        <div className="history-item" key={index}>
          <div className="address">{winner}</div>
          <div className="statistics">
            <div className="statistic">
              <strong>score: </strong>
              {highscores[index]}
            </div>
            <div className="statistic">
              <strong>payout: </strong>
              {utils.fromWei(payouts[index], "ether")} VET
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default History;
