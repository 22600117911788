import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import { Swipeable } from "react-swipeable";
import Swal from "sweetalert2";
import ActiveTiles from "../ActiveTiles";
import Grid from "../../classes/Grid";
import "./Board.scss";

const Board = forwardRef((params, ref) => {
  const {
    colCount,
    rowCount,
    boxSize,
    boxMargin,
    highscore,
    clearCacheForNewGame
  } = params;
  const [grid, setGrid] = useState(
    new Grid({ colCount, rowCount, boxSize, highscore })
  );
  const [timerID, setTimerID] = useState(0);

  useEffect(() => {
    startNewGame();
  }, []);

  useEffect(() => {
    setGrid(prev => {
      let newGrid = Object.create(prev.setBoxSize(boxSize));
      return newGrid;
    });
  }, [boxSize]);

  useEffect(() => {
    params.updateScores({ score: grid.score, highscore: grid.highscore });
  }, [grid.score, grid.highscore, params]);

  useEffect(() => {
    if (grid.won)
      Swal.fire({
        title: "Congratulations!",
        text: "You have reached the 2048! Do you want to continue",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Continue Playing",
        cancelButtonText: "Start a New Game",
        reverseButtons: true
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          startNewGame(true);
        }
      });
    clearInterval(timerID);
  }, [grid.won]);

  useEffect(() => {
    if (grid.lost) {
      if (grid.score >= grid.highscore) {
        Swal.fire({
          title: "Congratulations!",
          text: "You got the new highscore!",
          icon: "success",
          input: "text",
          inputPlaceholder: "Enter your secret to retrieve your funds",
          imageHeight: 88,
          imageWidth: 88,
          showCancelButton: false,
          confirmButtonText: "Withdraw"
        }).then(result => {
          if (result.value) {
            params.newHighscore(result.value, grid.score).then(() => {
              setGameStarted(false);
              startNewGame(true);
            });
          }
        });
      } else {
        Swal.fire({
          title: "Lost!",
          text: "Sorry, You have lost the game",
          imageUrl: "./images/sad.svg",
          imageHeight: 88,
          imageWidth: 88,
          showCancelButton: false,
          confirmButtonText: "Start a new Game!"
        }).then(result => {
          params.endGame().then(() => {
            startNewGame(true);
          });
        });
      }
    }

    clearInterval(timerID);
  }, [grid.lost, grid.score, grid.highscore]);

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);
    return () => document.removeEventListener("keydown", keyPressHandler);
  }, [grid]);

  const keyPressHandler = e => {
    if (e.keyCode >= 37 && e.keyCode <= 40) {
      e.preventDefault();
      let direction = -1;
      // 0 -> right, 1 -> down, 2 -> left, 3 -> up
      if (e.key === "ArrowRight") direction = 0;
      else if (e.key === "ArrowDown") direction = 1;
      else if (e.key === "ArrowLeft") direction = 2;
      else if (e.key === "ArrowUp") direction = 3;
      move(direction);
    }
  };

  const setGameStarted = value => {
    params.setGameStarted(value);
  };

  const startNewGame = clearCacheBefore => {
    if (clearCacheBefore) {
      clearCacheForNewGame();
    }

    setGrid(prev => {
      let newGrid = new Grid({ colCount, rowCount, boxSize, highscore });
      return newGrid;
    });
  };

  const move = direction => {
    setGrid(prev => {
      let newGrid = Object.create(prev.move(direction));
      return newGrid;
    });
  };

  useImperativeHandle(ref, () => ({
    newGame() {
      startNewGame(true);
    },
    random() {
      if (timerID) {
        clearInterval(timerID);
        setTimerID(0);
      } else {
        setTimerID(
          setInterval(() => {
            if (!grid.lost || !grid.won) {
              move(Math.floor(Math.random() * 4) + 1);
            }
          }, 50)
        );
      }
    }
  }));

  return (
    <Swipeable
      onSwipedRight={() => move(0)}
      onSwipedDown={() => move(1)}
      onSwipedLeft={() => move(2)}
      onSwipedUp={() => move(3)}
    >
      <div
        className="grid"
        style={grid ? { ...grid.inlineStyle(boxMargin) } : null}
      >
        {Array(colCount * rowCount)
          .fill(0)
          .map((box, i) => (
            <div key={i} className="box"></div>
          ))}
        <ActiveTiles grid={grid} boxMargin={boxMargin} />
      </div>
    </Swipeable>
  );
});
export default Board;
